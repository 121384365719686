.p-colorpicker-preview {
    width: 100%;
}

.p-colorpicker {
    margin-left: 3rem;
}

.my-border {
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
}